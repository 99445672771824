import ResolveRegion from "./ResolveRegion";

export default function TimeSheets(ts, state) {
    console.log('TimeSheets',ts)
    let types = state.t_work_type;
    let projects = state.t_project;
    let timesheets = [];
    if(state.ts){
        timesheets =  state.ts
    }
    Object.keys(ts).forEach(function (item) {

        if(item != 'Data' && item != 'Date'){
            let obj = {}
            obj.error = [];
            let emp = ts[item];

            obj.name =emp[0].A;
            obj.id =emp[0].B;
            obj.salary = 0;
            obj.regular = 0;
            obj.piecework = 0;
            obj.pto = 0;
            let rate = state.t_payrate.find(p => p.c_id ==  obj.id)
            if(rate){
                obj.rate = Number(rate.c_rate)
            }else{
                obj.rate = 0;
                obj.error.push('No Rate')
            }

            let cut = emp.splice(0, 2)
            let data = []

            // The following are to find overtime and evenly move regular hours to overtime project buckets
            obj.ot = emp[38].E; //Row 38 is overtime hour quantity
            if(obj.ot > 0){
                //console.log('ot',emp)
                // find all rows with hours in column F and if there are rows evenly subtract hours and add those hours to buckets
                let regs =  emp.filter(r => r.F > 0 && r.A); // rows with regular hours and row A has a value
                let divider = regs.length; // how many
                if(divider >0){ // If there are rows
                    let subtract = obj.ot/divider // how much to subtract from each row
                    regs.forEach(function (r) {
                        r.F = r.F-subtract;
                    })
                }
            }
            //console.log('emp',emp[38].E)
            //console.log('emp',emp)
            emp.forEach(function (r) {
                if(r.B ==='Totals' && (r.H || r.I) ){
                    console.log("************PTO")

                    if(r.H && r.H > 0){
                        console.log('H', r.H)
                        obj.pto += r.H
                    }
                    if(r.I && r.I > 0){
                        console.log('I', r.I)
                        obj.pto += r.I
                    }
                }
                if(r.A && r.D !=='Select One'){
                    let d = {}
                    d.type_fk = type(r.A, types);
                    if(d.type_fk == 0){
                        obj.error.push('No Type')
                    }
                    d.project_fk = type(r.D, projects)
                    if(d.project_fk == 0){
                        obj.error.push('No Project')
                    }
                    if(r.E){
                        d.salary = r.E
                        obj.salary += r.E
                    }
                    if(r.F){
                        d.regular = r.F
                        obj.regular += r.F
                    }
                    if(r.G){
                        d.piecework = r.G
                        obj.piecework += r.G
                    }
                    data.push(d)
                }
            })
            obj.data = data
            if(data.length ==0){
                obj.error.push('No Data')
            }
            timesheets.push(obj)
        }
    });

    console.log('timesheets',timesheets);
    return (timesheets)
}

function type(string, types){
    let fk = 0
    types.forEach(function (t) {
        if(string.indexOf(t.c_name)>-1){
            fk = t.recid
        }

    })
    return fk
}

