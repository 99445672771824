import ResolveRegion from "./ResolveRegion";
import moment from "moment";

export default function Summary(version, state, selected) {

    let regionFK = ResolveRegion('number', state)
    let thisYear = moment().year();
    let lastYear = thisYear - 1;
    let thisMonth = moment().month();
    let thisWeek = moment().isoWeek();

    let p2 = state.latest.filter(p => p && !p.void);
    let qb = state.qbC_num;
    if(state.poArray.length>0){
        //p2 = p2.filter(p => state.poArray.includes(p.c_num));
        //qb = qb.filter(q => state.poArray.includes(q.c_po));
    }
    let so = state.so;
    let yearRecs = [];
    let monthRecs = [];
    let weekRecs = [];
    let latest = [];

        if (selected) {
            Object.keys(selected).forEach(function (item) {
                state[item] = selected[item];
            });
        }

        let posHeaders = [];
        let tempHeaders = [];
        console.log('p2')
        p2.forEach(function (p) {
            let use = false
            if(state.poArray.length>0){
                if(state.poArray.includes(p.c_num)) {
                    use = true
                }
            }else{
                use = true
            }
            if(use){
                let header = tempHeaders.find(h => h.c_num == p.c_num);
                if(!header){
                    if (moment(p.c_date).year() >= lastYear) {
                        tempHeaders.push(p)
                        // add count column

                    }
                }
            }
        })
    tempHeaders = tempHeaders.sort((a, b) => (b.c_num > a.c_num) ? 1 : -1)
    let countHeaders = [];
    tempHeaders.forEach(function (p) {
        let count = {
            count:true,
            c_num:p.c_num+' '
        }
        countHeaders.push(count)
    })
    let tot = {
        c_num: 'Total'
    }
    tempHeaders.push(tot)
    tempHeaders.push({})

    let tot2 = {
        count:true,
        c_num: 'Counts'
    }
    countHeaders.push(tot2)
    posHeaders = [...tempHeaders, ...countHeaders]


        console.log('p2Groups')
        const p2Groups = p2.reduce((acc, obj) => {
            const year = `${moment(obj.c_date).year()}`;
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(obj);
            return acc;
        }, {});

    const noP2Groups = state.noP2.reduce((acc, obj) => {
        const year = `${moment(obj.c_date).year()}`;
        if (!acc[year]) {
            acc[year] = [];
        }
        acc[year].push(obj);
        return acc;
    }, {});

        console.log('qbGroups')
        const qbGroups = qb.reduce((acc, obj) => {
            const year = `${moment(obj.c_date).year()}`;
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(obj);
            return acc;
        }, {});
        console.log('soGroups')
        const soGroups = so.reduce((acc, obj) => {
            const year = `${moment(obj.c_date).year()}`;
            if (!acc[year]) {
                acc[year] = [];
            }
            acc[year].push(obj);
            return acc;
        }, {});

        let totalRecs = [...group2(lastYear, p2Groups[lastYear], qbGroups[lastYear], soGroups[lastYear], posHeaders, state, noP2Groups[lastYear]), ...group2(thisYear, p2Groups[thisYear], qbGroups[thisYear], soGroups[thisYear], posHeaders, state, noP2Groups[thisYear])]

    return {data: totalRecs, headers:posHeaders};


}

function sanitize(text){
    return text.replace(/ /g, '_')
}

function group2(year, p2Group, qbGroup, soGroup, posHeaders, state, noP2Groups){
    let regionFK = ResolveRegion('number', state)
    let pos = [];
    let posForQB = [];
    let latest = []; // array to keep track of which used
    if(p2Group){
        if(state.poArray.length>0){
            p2Group = p2Group.filter(p => state.poArray.includes(p.c_num));
        }
        p2Group.forEach(function (p) {
            p.salesOrders = 0;
            p.salesOrdersCount = 0;
            p.outstanding = 0;
            p.outstandingCount = 0;
            p.noP2Total = 0;
            p.noP2TotalCount = 0;
            state.cifaList.forEach(function (status) {
                p[sanitize(status)] = 0;
                p[sanitize(status)+'count'] = 0;
            })
            let thisPo = pos.find(h => h.c_num == p.c_num);
                if (p.c_region_fk == regionFK) {
                    let foundPo = state.qb.find(s => s.c_base_inv_num == p.c_base_inv_num);

                    //Commented this out to include p2s missing in qb. This makes the summary match the comparison
                   // if (foundPo && foundPo.c_po == p.c_num) {
                        state.cifaList.forEach(function (status) {
                            if (p.c_CIFA_status === status) {
                                if(!thisPo){
                                    if(status !=='All' && status !=='SCHEDULED TO PAY' && status !=='PAID'){
                                        p.outstanding += p.c_invoice_amount;
                                        p.outstandingCount ++;
                                    }
                                    p[sanitize(status)] = p.c_invoice_amount;
                                    p[sanitize(status)+'count'] ++;
                                }else{
                                    if(status !=='All' && status !=='SCHEDULED TO PAY' && status !=='PAID'){
                                        thisPo.outstanding += p.c_invoice_amount;
                                        thisPo.outstandingCount ++;
                                    }
                                    thisPo[sanitize(status)] += p.c_invoice_amount;
                                    //p[sanitize(status)+'count'] ++;
                                    thisPo[sanitize(status)+'count'] ++;
                                }

                            }
                        })
                   // }
                }
            if(!thisPo){
                pos.push(p)
            }
        })

    }

    if(noP2Groups) {
        noP2Groups.forEach(function (p) {
            if (p.noP2) {
                let thisPo = pos.find(h => h.c_num == p.c_num);
                if (thisPo) {
                    thisPo.noP2Total += p.c_invoice_amount;
                    thisPo.noP2TotalCount ++;

                }
            }
        })
    }

    // if(qbGroup){
    //     if(state.poArray.length>0){
    //         qbGroup = qbGroup.filter(q => state.poArray.includes(q.c_po));
    //     }
    //
    //     qbGroup.forEach(function (q) {
    //
    //         let inv = state.p2.filter(p => p.c_base_inv_num == q.c_num);
    //         if (inv.length==0) {
    //
    //             if (q.c_region_fk == regionFK) {
    //                 let found = pos.find(l => l.c_num == q.c_po);
    //                 if (found) {
    //                     found.outstanding += q.c_amount;
    //                     found.salesOrders += q.c_amount;
    //                 } else {
    //                     let obj = {
    //                         outstanding: q.c_amount,
    //                         salesOrders: q.c_amount
    //                     }
    //                     pos.push(obj)
    //                 }
    //             }
    //         }
    //     })
    // }

    if(soGroup){
        // soGroup.forEach(function (s) {
        //     if (s.c_region_fk == regionFK) {
        //         let found = pos.find(l => l.c_num == s.c_po);
        //         if(found){
        //             found.outstanding += s.c_amount;
        //             found.salesOrders += s.c_amount
        //         }else{
        //            let obj = {
        //                outstanding: s.c_amount,
        //                salesOrders: s.c_amount
        //            }
        //            pos.push(obj)
        //         }
        //     }
        // })
    }


    //console.log('groups',groups)
    let data = []
    let obj = {col1:year, col2:null, col3:'P2 Information', col4:null}

    posHeaders.forEach(function (header) {
        obj[header.c_num] = header.c_num
    })
    data.push(obj)
    let inc = 1
    state.cifaList.forEach(function (status) {
        if(status !=='All' && status !=='SCHEDULED TO PAY'){
            let row = {col1:null, col2:inc, col3:status, col4:null}
            let tot = 0;
            let countTot = 0;
            pos.forEach(function (po) {
                if(row.col3 == status){
                    tot +=po[sanitize(status)]
                    row[po.c_num] = po[sanitize(status)]
                    row[po.c_num+' '] = po[sanitize(status)+'count']
                    countTot += po[sanitize(status)+'count']
                }
            })
            row.Total = tot
            row.Counts = countTot
            data.push(row)
            inc++
        }
    })
    data.push({})
    // Bring the below back when they start using the sales orders
    //data.push({col1:null, col2:null, col3:'QuickBooks', col4:null})
//Put this in the below object:   col3:'Sales Order in QuickBooks'

    let soRow = {col1:null, col2:inc, col3:'QuickBooks No P2', col4:null}
    let tot2 = 0;
    let countTot2 = 0;
    pos.forEach(function (po) {
        tot2 +=po.noP2Total;
        soRow[po.c_num] = po.noP2Total;
        soRow[po.c_num+' '] = po.noP2TotalCount;
        countTot2+= po.noP2TotalCount;
    })
    soRow.Total = tot2;
    soRow.Counts = countTot2
    data.push(soRow)

    //data.push({col1:null, col2:inc, col3:'Sales Order in QuickBooks', col4:null})
    data.push({})

    let osRow = {col1:null, col2:null, col3:'Total Outstanding Money from Comcast', col4:null}
    let tot3 = 0;
    let countTot3 = 0;
    pos.forEach(function (po) {
        tot3 +=po.outstanding;
        osRow[po.c_num] = po.outstanding;
        osRow[po.c_num+' '] = po.outstandingCount;
        countTot3+= po.outstandingCount;
    })
    osRow.Total = tot3;
    osRow.Counts = countTot3;
    data.push(osRow)

    //data.push({col1:null, col2:null, col3:'Total Outstanding Money from Comcast', col4:null})
    data.push({})

    let spRow = {col1:null, col2:null, col3:'Schedule to Pay', col4:null}
    let tot4 = 0;
    let countTot4 = 0;
    pos.forEach(function (po) {
        tot4 +=po['SCHEDULED_TO_PAY']
        spRow[po.c_num] = po['SCHEDULED_TO_PAY']
        spRow[po.c_num+' '] = po['SCHEDULED_TO_PAY'+'count']
        countTot4 += po['SCHEDULED_TO_PAY'+'count']
    })
    spRow.Total = tot4;
    spRow.Counts = countTot4;
    data.push(spRow)

   //data.push({col1:null, col2:null, col3:'Schedule to Pay', col4:null})
    data.push({},{})
    return data;
}
