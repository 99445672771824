import ResolveRegion from "./ResolveRegion";

export default function QbP2Update(qb, p2, state, db) {
    //console.log('p2',p2)
    let tempQb;
    let tempP2;
    if(qb){
        if(db){
            tempQb = qb;
        }else{
            tempQb = [...state.qb, ...qb];
        }
        tempP2 = state.p2
    }
    if(p2){
        if(db){
            tempP2 = p2;
        }else{
            tempP2 = [...state.p2, ...p2];
        }
        tempQb = state.qb;
    }
    tempQb.forEach(function (q) {
        tempP2.forEach(function (p) {
            if (q.c_num == p.c_base_inv_num) {
                p.hasQB = true;
                if(q.c_amount == 0){
                    p.void = true
                }
                q.related = true;
                p.related = true;
            }
        })
    })

    return {qb:tempQb, p2:tempP2};
}
function stripLetters(num){
    let st = ''
    if(num){
        st = num.toString()
    }
    return st.replace(/\D/g,'');
}
