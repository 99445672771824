import ResolveRegion from "./ResolveRegion";
import moment from "moment";

export default function Paysum(state) {
    let data = [];
    let pay = [{name:'Hrs', type:'regular'},{name:'OT', type:'ot'},{name:'PW', type:'piecework'},{name:'PTO', type:'pto'}];
    let headers = []
    let salaryRow = {};
    let salaryRowTotal = 0
    state.t_project.forEach(proj => {
        let salary = 0;
        state.ts.forEach(emp => {
            if(emp.error.length === 0) {
                emp.data.forEach(item => {

                    if(item.project_fk == proj.recid){
                        console.log('item.salary',item)
                        if(item.salary){
                            let head = headers.find(object => object.recid === item.project_fk)
                            if(!head){
                                proj.total = item.salary;
                                headers.push(proj)
                            }else{
                                head.total += item.salary;
                            }
                            salary += item.salary;
                            salaryRowTotal += item.salary;
                        }
                    }
                })
            }
        })
        salaryRow['col'+proj.recid] = salary
    })
    salaryRow.total = salaryRowTotal;
    salaryRow.name = 'Salary';
    data.push(salaryRow)
    data.push({})
    state.t_work_type.forEach(type => {
        console.log('t_work_type',type)
        pay.forEach(pt => {
            let row = {}
            let rowTotal = 0
            row.name = type.c_name+' '+pt.name
            state.t_project.forEach(proj => {
                let val = 0;
                state.ts.forEach(emp => {
                    if(emp.error.length === 0){
                        emp.data.forEach(item => {
                            //console.log('item',item.project_fk,proj.recid,pt.type)
                            if(item.project_fk == proj.recid && item.type_fk == type.recid && item[pt.type]){

                                let temp = item[pt.type]
                                if(pt.type === 'regular'){
                                    temp = item[pt.type]*emp.rate
                                }
                                val += temp;
                                rowTotal += temp;
                                let head = headers.find(object => object.recid === item.project_fk)
                                if(!head){
                                    proj.total = temp;
                                    //console.log('new head',proj.c_name,proj.total)
                                    headers.push(proj)
                                }else{
                                    head.total += temp;
                                    //console.log('new head',head.c_name,head.total)
                                }
                            }
                        })
                    }
                })
                row['col'+proj.recid] = val
            })
            row.total = rowTotal
            data.push(row)
        })
    });

    //console.log('headers',headers)
    data.push({},{})
    let totalRow = {}
    let total = 0
    headers.forEach(proj => {
        //console.log('tot',proj)
        total += proj.total
        totalRow.name = 'Total';
        totalRow['col'+proj.recid] = proj.total;
    })
    totalRow.total = total
    data.push(totalRow)
    return {data:data,headers:headers};
}
