export default function ResolveRegion(type, data) {
    let result;
    if(data.selectedRegion === "All"){
        return 'All';
    }
    if(type === 'number'){
        result = data.t_region.find(r => r.c_name == data.selectedRegion).recid;
    }else{
        result = data.t_region.find(r => r.recid == data).c_name;
    }
    return result;
}
