import React, { } from 'react';
import { Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux';
import history from "./utils/history";
import Reducer from './utils/Reducer';
import Grid from './Grid'

function createThunkMiddleware(extraArgument) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState, extraArgument);
    }
    return next(action);
  };
}

const middlewareEnhancer = applyMiddleware(createThunkMiddleware('path'))
let store
if(process.env.NODE_ENV === 'development'){
  store = createStore(Reducer, compose(middlewareEnhancer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()));
}else{
  store = createStore(Reducer, compose(middlewareEnhancer));
}


function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Grid />
      </Router>
    </Provider>
  );

}


export default App;
