import React, {useState} from "react"
import { useSelector } from 'react-redux'

export const Loading = (props) => {
    return (
        <div>
                <div  className="loading">Loading...</div>
                <div className="loader"/>
        </div>
    )
}

export default Loading
