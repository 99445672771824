import React, { useEffect, useState, useRef } from 'react';
import { Router, Redirect } from 'react-router-dom';
import SwitchRoutes from './routes/SwitchRoutes';
import Header from './components/Header';
import Nav from './components/Nav';
import Footer from './components/Footer';
import history from "./utils/history";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import useAfterLoad from './utils/useAfterLoad'

export function Grid(props) {

    const reduxState = useSelector(state => state);
    const countRef = useRef(reduxState.lock);

    const [isOpenPreload, setIsOpenPreload] = useState(false);

    function togglePreloadModal() {
        setIsOpenPreload(!isOpenPreload)
    }
    let timerOpen;
    let timerClose;

    useAfterLoad(() => {
        console.log('useAfterLoad',reduxState.path)
        if(reduxState.path){
            history.push(reduxState.path)
        }
    }, [reduxState.path])


    return (<>

        <div className="container">
            <div className="header">
                <Header />
                <Nav />
            </div>
            <div className="content">
                <Redirect to={{ pathname: '/login' }} />
                <SwitchRoutes />
            </div>
        </div>
    </>
    )
}

export default Grid
