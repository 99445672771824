export default function YOY(data) {
    data.map((item) => {
      item.month = resolveMonth(item.report_month);
    });
    // there are a group of rows, each with a month. I want to add a row every time the month changes.

    let newData = [];
    let lastRegion = data[0].region_fk;
    let count_current_year = 0;
    let amount_current_year = 0;
    let count_last_year = 0;
    let amount_last_year = 0;
    let count_two_years_ago = 0;
    let amount_two_years_ago = 0;
    let projected_count_current_month = 0;
    let projected_amount_current_month = 0;
    data.map((item) => {
        console.log(item.region_fk, lastRegion)
      if (item.region_fk !== lastRegion) {
          newData.push({month:'Total',count_current_year, amount_current_year, count_last_year, amount_last_year, count_two_years_ago, amount_two_years_ago, projected_count_current_month, projected_amount_current_month});
          newData.push({})
          count_current_year = 0;
          amount_current_year = 0;
          count_last_year = 0;
          amount_last_year = 0;
          count_two_years_ago = 0;
          amount_two_years_ago = 0;
          projected_count_current_month = 0;
          projected_amount_current_month = 0;
          lastRegion = item.region_fk;
      }
        count_current_year += item.count_current_year;
        amount_current_year += Number(item.amount_current_year);
        count_last_year += item.count_last_year;
        amount_last_year += Number(item.amount_last_year);
        count_two_years_ago += item.count_two_years_ago;
        amount_two_years_ago += Number(item.amount_two_years_ago);
        projected_count_current_month += item.projected_count_current_month;
        projected_amount_current_month += Number(item.projected_amount_current_month);
        newData.push(item);
    });
    newData.push({month:'Total',count_current_year, amount_current_year, count_last_year, amount_last_year, count_two_years_ago, amount_two_years_ago, projected_count_current_month, projected_amount_current_month});

return newData;

}

function resolveMonth(month) {
    switch (month) {
        case 1:
            return 'January';
        case 2:
            return 'February';
        case 3:
            return 'March';
        case 4:
            return 'April';
        case 5:
            return 'May';
        case 6:
            return 'June';
        case 7:
            return 'July';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'October';
        case 11:
            return 'November';
        case 12:
            return 'December';

        default:
            return 0;
    }
}
