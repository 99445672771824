import React, {Suspense, useState} from 'react';
import { useSelector} from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import history from "../utils/history";
import useAfterLoad from "../utils/useAfterLoad";

const Nav = () => {

    const reduxState = useSelector(state => state);

    const [tabIndex, setTabIndex] = useState(0)

    function handleClick(tab) {
        history.push(tab)
    }


    const makeTabs= (() => {
        if(reduxState.t_permission){
            let out = [];
            let out2 = [];
            let inc = 0
            if(reduxState.t_permission.length>1){
                reduxState.t_permission.forEach((perm) => {
                    if(perm.recid !=2){
                        out.push(<Tab key={'t'+inc} onClick={() => {handleClick(perm.c_component) }}>{perm.c_name}</Tab>);
                        out2.push(<TabPanel key={'tp'+inc}/>)
                        inc++
                    }
                })
            }

            return (
                <Tabs>
                    <TabList style={{ fontSize: '12px', fontWeight: 'bold', marginTop:'5px', marginBottom:'0px'}} >{out}</TabList>
                    {out2}
                </Tabs>
            )
        }
    });

    return (<>
        {reduxState.t_permission ?
                <div  className="nav">
                    {makeTabs()}
                </div>
                :
                <></>}</>

    )
}

export default Nav
