import React from 'react'
import { useSelector } from 'react-redux'
import HeaderSettings from "./HeaderSettings";
import history from "../utils/history";
const Header = () => {
    const reduxState = useSelector(state => state);

    return (
        <div  >
            <div className='page' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', top: '0px', marginTop: '0px' }}>
               <div><div onClick={() => { history.push('/main') }} className='headerLogo' />
                <div style={{ fontSize: '10px', paddingLeft:'12px' }} >{reduxState.version}</div></div>
                <HeaderSettings style={{ textAlign: 'right' }} />

            </div>
        </div>
    )
}

export default Header
