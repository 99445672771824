import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useStore } from "react-redux";
import Controller from "../Controller";
import Loading from "../components/Pages/Loading";

export const SwitchRoutes = (props) => {

    const reduxState = useSelector(state => state);
    //console.log('SwitchRoutes', reduxState.account)


    const makeRoutes = (() => {

        let out = [];
        let inc = 0
        out.push(<Route key="main" path="/main" component={React.lazy(() => import('../components/Pages/Main'))} />);
        out.push(<Route key="login" path="/Login" component={React.lazy(() => import('../components/Pages/Login/Login'))} />);
        out.push(<Route key="pass" path="/passwordRecovery" component={React.lazy(() => import('../components/Pages/Login/PasswordRecovery'))} />);

        if (reduxState.account && reduxState.account.c_type === 1) {
            out.push(<Route key="comparison" path="/compare" component={React.lazy(() => import('../components/Pages/Compare/Compare'))} />);
            out.push(<Route key="qb" path="/qb" component={React.lazy(() => import('../components/Pages/QB/QB'))} />);
            out.push(<Route key="so" path="/so" component={React.lazy(() => import('../components/Pages/SO/SO'))} />);
            out.push(<Route key="p2" path="/p2" component={React.lazy(() => import('../components/Pages/P2/P2'))} />);
            out.push(<Route key="po" path="/po" component={React.lazy(() => import('../components/Pages/PO/PO'))} />);
            out.push(<Route key="summary" path="/summary" component={React.lazy(() => import('../components/Pages/Summary/Summary'))} />);
            out.push(<Route key="converter" path="/converter" component={React.lazy(() => import('../components/Pages/Converter/Converter'))} />);
            out.push(<Route key="rr" path="/ratesRegions" component={React.lazy(() => import('../components/Pages/RatesRegions/RatesRegions'))} />);
            out.push(<Route key="charts" path="/charts" component={React.lazy(() => import('../components/Pages/Charts/Charts'))} />);
            out.push(<Route key="rules" path="/rules" component={React.lazy(() => import('../components/Pages/Rules/Rules'))} />);
            out.push(<Route key="yearoveryear" path="/yearoveryear" component={React.lazy(() => import('../components/Pages/YearOverYear/YearOverYear'))} />);
        }
        if (reduxState.account && reduxState.account.c_type === 2) {
            out.push(<Route key="timesheet" path="/timesheet" component={React.lazy(() => import('../components/Pages/payroll/Timesheet'))} />);
            out.push(<Route key="summary" path="/Summary" component={React.lazy(() => import('../components/Pages/payroll/Summary'))} />);
            out.push(<Route key="ratesheet" path="/RateSheet" component={React.lazy(() => import('../components/Pages/payroll/RateSheet'))} />);
            out.push(<Route key="project" path="/Project" component={React.lazy(() => import('../components/Pages/payroll/Project'))} />);
            out.push(<Route key="workType" path="/WorkType" component={React.lazy(() => import('../components/Pages/payroll/WorkType'))} />);
        }
        if (reduxState.account && reduxState.account.c_type === 3) {
            out.push(<Route key="converter" path="/converter" component={React.lazy(() => import('../components/Pages/Converter/Converter'))} />);
            out.push(<Route key="rr" path="/ratesRegions" component={React.lazy(() => import('../components/Pages/RatesRegions/RatesRegions'))} />);
        }
        if (reduxState.account && reduxState.account.c_type === 4) {
            out.push(<Route key="converter" path="/converter" component={React.lazy(() => import('../components/Pages/Converter/Converter'))} />);
        }

        return (
            <Switch>
                <Suspense fallback={<Loading />}>{out}</Suspense>
            </Switch>
        )
    });

    return (<>{makeRoutes()}</>)
}
export default SwitchRoutes
