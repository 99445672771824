import React from 'react'
import { useSelector, useStore } from 'react-redux';
import Clock from 'react-live-clock';
import Controller from "../Controller";

const HeaderSettings = () => {
    const reduxState = useSelector(state => state);
    const store = useStore();
    let user;
    let title;
    //console.log('HeaderSettings',reduxState.account)
    if(reduxState.account){
        user = reduxState.account.c_name_first;
        title = reduxState.account.c_title;
    }

    const submit = (e) => {
        let params = {
            type:'LOGOUT',
            path:'/main',
            endpoint:'/user/logout',
            body:{"recid": user.recid}
        }
        store.dispatch(Controller(params))
    }
    const reset = (e) => {
        store.dispatch({ type: 'RESET' })
    }

    return (
       <>{user ?
           <div className='headerSettings'>
               <div><button className='linkButton' onClick={submit}>Sign Out</button></div>
               <div>{title}</div>
               <div><button className='linkButton' onClick={reset}>Reset</button></div>
            </div>
           :<></>}</>

    )
}

export default HeaderSettings
