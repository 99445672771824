import axios from "axios";
import { saveAs } from 'file-saver';
import fs from 'fs'
import history from "./utils/history";
import fileDownload from 'js-file-download';
import Download from './utils/Download';

export function Controller(params) {
    console.log('Controller', params)
    let config = {}
    config.headers = {};
    let localToken = localStorage.getItem("token");
    if (localToken) {
        config.headers.authorization = "Bearer " + localToken;
    }
    if (params.type === 'REPORT') {
        config.headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        config.headers.responseType = "bufferArray";
    }
    return async function (dispatch, getState) {
        let state = getState();
        try {
            let payload = await axios.post(state.url + params.endpoint, params.body, config);
            console.log('payload', payload)
            if (!payload.data.error) {
                if (params.type === 'REPORT') {
                    //const blob = new Blob([s2ab(atob(payload.data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
                    fileDownload(payload.data, 'file.xlsx');
                }
                dispatch({ type: params.type, payload: payload, params: params });
                if (params.path) {
                    history.push(params.path);
                }
            } else {
                handleError(dispatch, payload.data.error)
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    }
}
export default Controller

function s2ab(s) {

    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;

}

function handleError(dispatch, err) {
    console.log('error', err);
    let msg = err;
    if (err.info) {
        let str = err.info.msg;
        if (str.includes("Data too long for column")) {
            msg = 'Field is too long.';
        }
    }
    if (err.custom) {
        msg = err.custom
    }

    if (err === 'Invalid Username or Password') {
        localStorage.removeItem("token");
        history.push('/login');
    }

    alert(msg)
}

