import reportHeaders from "../jsonData/reportHeaders.json";
import moment from "moment";
import CompareDb from "./CompareDb";
import Chart from "./Chart";
import Summary from "./Summary";
import YOY from "./YOY";
import SummaryDb from "./SummaryDb";
import React from "react";
import QbP2Update from "./QbP2Update";
import DbUpdate from "./DbUpdate";
import SalesOrderSummary from "./SalesOrderSummary";
import TimeSheets from "./TimeSheets";
import Paysum from "./Paysum";
let sortManager = [];

let currentDate = new Date();
currentDate.setHours(0);
currentDate.setMinutes(0);
currentDate.setSeconds(0);
currentDate.setMilliseconds(0);

let permission = [
    {
        c_component: 'qb',
        c_name: 'Quickbooks',
        c_type: 1
    },
    // {
    //     c_component: 'so',
    //     c_name: 'Sales Orders',
    //     c_type:1
    // },
    {
        c_component: 'p2',
        c_name: 'P2 Salesforce',
        c_type: 1
    },
    {
        c_component: 'po',
        c_name: 'P.O.s',
        c_type: 1
    },
    {
        c_component: 'compare',
        c_name: 'Comparison',
        c_type: 1
    },
    {
        c_component: 'summary',
        c_name: 'Summary',
        c_type: 1
    },
    {
        c_component: 'Charts',
        c_name: 'Charts',
        c_type: 1
    },
    {
        c_component: 'Converter',
        c_name: 'Converter',
        c_type: 1
    },
    {
        c_component: 'RatesRegions',
        c_name: 'Regions, Counties & Rates',
        c_type: 1
    },
    {
        c_component: 'Rules',
        c_name: 'Rules',
        c_type: 1
    },
    {
        c_component: 'Timesheet',
        c_name: 'Timesheet',
        c_type: 2
    },
    {
        c_component: 'Summary',
        c_name: 'Summary',
        c_type: 2
    },
    {
        c_component: 'Ratesheet',
        c_name: 'Ratesheet',
        c_type: 2
    },
    {
        c_component: 'Project',
        c_name: 'Project',
        c_type: 2
    },
    {
        c_component: 'WorkType',
        c_name: 'Work Type',
        c_type: 2
    },
    {
        c_component: 'Converter',
        c_name: 'Converter',
        c_type: 3
    },
    {
        c_component: 'RatesRegions',
        c_name: 'Regions, Counties & Rates',
        c_type: 3
    },
    {
        c_component: 'Converter',
        c_name: 'Converter',
        c_type: 4
    },
]
let url = ''
if (process.env.NODE_ENV === 'development') {
    url = "http://localhost:8443"
} else {
    url = "https://ct.buckleycableconstruction.com:8443"
    // url = "https://167.172.249.70:8443"
}


const initialState = {

    currentDate: currentDate,
    currRoute: "/",
    data: null,
    edit: false,
    historyLength: 0,
    latest: [],
    loaded: true,
    dataLoaded: false,
    noP2: [],
    ignored: [],
    now: currentDate, //new Date(),
    pageTitle: 'Buckley',
    path: null,
    invalid: null,
    invalidMessage: null,
    qb: [],
    so: [],
    soSummary: [],
    ts: [],
    p2: [],
    compareP2: [],
    compare: [],
    summary: [],
    yoy: [],
    summaryHeaders: [],
    chart: null,
    reportHeaders: reportHeaders,
    t_invoice: null,
    t_permission: null,
    chartList: getChartList(),
    selectedChart: 'Paid VS Owed',
    summaryList: getSummaryList(),
    selectedSummary: 'Original',
    qbP2List: getQbP2List(),
    selectedRelated: null,
    regionList: ['All'],
    favoriteList: ['None'],
    selectedFavorite: 'None',
    selectedRegion: 'All',
    statusList: ['All'],
    selectedStatus: 'All',
    cifaList: ['All'],
    selectedCifa: 'All',
    cifaArray: [],
    poList: ['All'],
    poArray: [],
    poArrayChanged: false,
    selectedPo: 'All',
    yearList: getYears(),
    selectedYear: 'All', //moment().year().toString(),
    structure: null,
    t_user: null,
    account: null,
    valueLists: null,
    headerHeight: 200,
    version: 'v1.2.23',
    windowHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
    windowWidth: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    url: url


}
function getYears() {
    let arr = ['All']
    let year = moment().year().toString();
    arr.push((year - 4).toString())
    arr.push((year - 3).toString())
    arr.push((year - 2).toString())
    arr.push((year - 1).toString())
    arr.push((year).toString())
    return arr;
}
function getQbP2List() {
    let arr = []
    arr.push('All')
    arr.push('Without related')
    arr.push('With related')
    return arr
}
function getChartList() {
    let arr = []
    arr.push('Paid VS Owed')
    arr.push('Amount VS Reserve')
    arr.push('Cluster Stacked')
    return arr
}
function getSummaryList() {
    let arr = []
    arr.push('Original')
    arr.push('Monthly Year/Year')
    return arr
}

function addHistory(state) {
    // if (history.length > 5) {
    //     history.shift();
    // }
    // history.push(state);
    // return history.length;
}
function getHistory() {
    // if (history.length > 0) {
    //     return history.pop()
    // }
}

function testCall(state) {
    // dispatch({ type: 'CALL'})
}

export default function Reducer(state = initialState, action) {

    switch (action.type) {

        case "CALL":
            console.log('CALL', action)
            return {
                ...state
            }

        case "DUD":
            console.log('DUD', action)

            return {
                ...state
            }
        case "LaborCodeUpdated":
            console.log('LaborCodeUpdated', action)
            let rates = state.t_rate
            let code = rates.find(r => r.recid === action.payload.data.recid)
            if (code) {
                code.c_labor_code = action.payload.data.c_labor_code
            }
            return {
                ...state,
                t_rate: rates
            }
        case "REPORT":
            console.log('REPORT')
            return {
                ...state
            }
        case "INVOICE":
            console.log('INVOICE', action.payload.data.t_invoice)
            return {
                ...state,
                t_invoice: action.payload.data.t_invoice
            }
        case "LOGIN":
            console.log('LOGIN', action)
            if (action.payload.data.recid) {
                localStorage.setItem("loggedIn", action.payload.data.recid);
            }
            if (action.payload.data.account.token) {
                localStorage.setItem("token", action.payload.data.account.token);
            }
            let perms = []
            permission.forEach(function (p) {
                if (p.c_type === action.payload.data.account.c_type) {
                    perms.push(p);
                }
            })
            let lobj = {
                ...state,
                path: action.payload.data.path,
                account: action.payload.data.account,
                t_user: action.payload.data.t_user,
                t_permission: perms,
            }
            if (action.payload.data.account.c_type === 1) {
                lobj.t_region = action.payload.data.t_region;
                lobj.t_county = action.payload.data.t_county;
                lobj.t_rate = action.payload.data.t_rate;
                lobj.t_favorite = action.payload.data.t_favorite;
                lobj.t_item = action.payload.data.t_item;
                lobj.t_po = action.payload.data.t_po.sort((a, b) => (a.c_num > b.c_num) ? -1 : 1)
            }
            if (action.payload.data.account.c_type === 2) {
                lobj.t_payrate = action.payload.data.t_payrate;
                lobj.t_project = action.payload.data.t_project;
                lobj.t_work_type = action.payload.data.t_work_type;
            }
            let regionList = state.regionList
            if (action.payload.data.account.c_type === 1) {
                action.payload.data.t_region.forEach(function (r) {
                    if (!regionList.find(s => s == r.c_name)) {
                        regionList.push(r.c_name);
                    }
                })
                lobj.selectedRegion = regionList[0];
            }
            let favoriteList = state.favoriteList
            if (action.payload.data.account.c_type === 1) {
                action.payload.data.t_favorite.forEach(function (r) {
                    if (!favoriteList.find(s => s == r.c_name)) {
                        favoriteList.push(r.c_name);
                    }
                })
                lobj.selectedFavorite = favoriteList[0];
            }

            if (action.payload.data.account.c_type === 3) {
                lobj.t_region = action.payload.data.t_region;
                lobj.t_county = action.payload.data.t_county;
                lobj.t_rate = action.payload.data.t_rate;
                lobj.t_payrate = action.payload.data.t_payrate;
                lobj.t_item = action.payload.data.t_item;

                action.payload.data.t_region.forEach(function (r) {
                    if (!regionList.find(s => s == r.c_name)) {
                        regionList.push(r.c_name);
                    }
                })
                lobj.selectedRegion = regionList[0];
            }
            if (action.payload.data.account.c_type === 4) {
                lobj.t_region = action.payload.data.t_region;
                lobj.t_county = action.payload.data.t_county;
                lobj.t_rate = action.payload.data.t_rate;
                lobj.t_payrate = action.payload.data.t_payrate;
                lobj.t_item = action.payload.data.t_item;

                action.payload.data.t_region.forEach(function (r) {
                    if (!regionList.find(s => s == r.c_name)) {
                        regionList.push(r.c_name);
                    }
                })
                lobj.selectedRegion = regionList[0];
            }

            return lobj

        case "LOGOUT":
            console.log('LOGOUT', action)
            localStorage.setItem("loggedIn", '');
            localStorage.setItem("token", '');
            return {
                ...state,
                path: '/login',
                account: null,
                t_role: null,
                t_permission: null,
                t_location: null,
                qb: [],
                so: [],
                soSummary: [],
                p2: [],
                compare: [],
                summary: [],
                loaded: true
            }
        case "RESET":
            console.log('RESET', action)
            return {
                ...state,
                qb: [],
                so: [],
                soSummary: [],
                p2: [],
                ts: [],
                compare: [],
                summary: [],
                poArray: [],
                latest: [],
                noP2: [],
                compareP2: [],
                statusList: ['All'],
                cifaList: ['All'],
                report: [],
                summaryHeaders: [],
                chart: null
            }
        case "LOADING":
            console.log('RESET', action)
            return {
                ...state,
                loaded: false
            }
        case "GO_BACK":
            return {
                ...getHistory(),
                lock: false
            }
        case "so":
            console.log('so', action)
            return {
                ...state,
                so: action.payload.data.data,
                soSummary: SalesOrderSummary(action.payload.data.data, state),
                soStatus: action.payload.data.status
            }
        case "qb":
            console.log('qb', action)
            let res = QbP2Update(action.payload.data.data, null, state);
            return {
                ...state,
                qb: res.qb,
                p2: res.p2,
                loaded: true
            }
        case "qbdb":
            console.log('qb', action)
            let resqb = QbP2Update(action.payload.data.data, null, state, true);
            return {
                ...state,
                qb: resqb.qb,
                p2: resqb.p2,
                loaded: true
            }
        case "p2":
            console.log('p2', action)
            let res2 = QbP2Update(null, action.payload.data.data, state);
            return {
                ...state,
                p2: res2.p2,
                qb: res2.qb,
                loaded: true
            }
        case "p2db":
            console.log('p2', action)
            let resp2 = QbP2Update(null, action.payload.data.data, state, true);
            return {
                ...state,
                p2: resp2.p2,
                qb: resp2.qb,
                loaded: true
            }
        case "item":
            return {
                ...state,
                t_item: action.payload.data.data
            }
        case "rate":
            console.log('rate', action)
            return {
                ...state,
                t_rate: action.payload.data.data
            }
        case "pr":
            console.log('pr', action)
            return {
                ...state,
                t_payrate: action.payload.data.data,
                prStatus: action.payload.data.status
            }
        case "ts":
            //console.log('upload', action)
            let ts = TimeSheets(action.payload.data.data, state)
            return {
                ...state,
                ts: ts,
                loaded: true
            }
        case "SET_SELECTED_FILE":
            console.log('SET_SELECTED_FILE', action)
            return {
                ...state,
                selectedFile: action.payload,
                loaded: false
            }
        case "csv":
            console.log('upload pdf', action)
            let csv = action.payload.data
            return {
                ...state,
                csv: csv,
                loaded: true
            }
        case "CLEAR_CSV":
            console.log('CLEAR_CSV', action)
            return {
                ...state,
                csv: null,
                loaded: false
            }
        case "PAYROLLSUMMARY":
            let paysum = Paysum(state)
            return {
                ...state,
                payrollSummaryLoaded: true,
                paysum: paysum.data,
                payHeaders: paysum.headers
            }


        case "compareDb":
            let data = action.payload.data
            console.log('compareDb', data)
            //let update = DbUpdate(data.t_invoice, data.t_po)
            data.selectedCifa = state.selectedCifa
            data.selectedStatus = state.selectedStatus
            data.selectedYear = state.selectedYear
            let compare1 = CompareDb(data)
            return {
                ...state,
                loaded: true,
                dataLoaded: true,
                compareP2: data.p2,
                qb: data.qb,
                qbC_num: data.qbC_num,
                noP2: data.noP2,
                ignored: data.ignored,
                latest: data.latest,
                statusList: data.status,
                cifaList: data.cifa,
                compare: compare1.comp,
                report: compare1.report
            }
        case "UPDATE_ROW":
            console.log('UPDATE_ROW', action)
            let compare = state.compare
            let found = compare.find(f => f.recid == action.payload.data.recid)
            if (found) {
                found.c_exclude = action.payload.data.c_exclude
            }
            return {
                ...state,
                compare: compare
            }

        case "LOADED_COMPARE":
            console.log('LOADED_COMPARE', action)
            let thisData2 = {}
            thisData2.selectedCifa = state.selectedCifa
            thisData2.selectedStatus = state.selectedStatus
            thisData2.selectedYear = state.selectedYear
            thisData2.p2 = state.p2
            thisData2.qb = state.qb
            thisData2.qbC_num = state.qbC_num
            thisData2.noP2 = state.noP2
            thisData2.ignored = state.ignored
            thisData2.latest = state.latest
            thisData2.statusList = state.statusList
            thisData2.cifaList = state.cifaList

            let compare2 = CompareDb(thisData2)
            return {
                ...state,
                compare: compare2.comp,
                report: compare2.report
            }
        case "UPDATE_COMPARE":
            console.log('UPDATE_COMPARE', action)
            let objc = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                objc[item] = action.data[item];
            });

            if (action.reset) {
                console.log('RESET_COMPARE', action)
                objc.compare = []
                objc.poArrayChanged = true
                objc.dataLoaded = false
            } else {
                let comp = CompareDb(state, action.data)
                objc.compare = comp.comp
                objc.report = comp.report
            }

            return objc;

        case "CLEAR_COMPARE":
            console.log('CLEAR_COMPARE', action)
            return {
                ...state,
                compare: [],
                poArrayChanged: false,
            }

        case "SUMMARY":
            let data1 = action.payload.data
            //let update1 = DbUpdate(data1.t_invoice, data1.t_po)
            data1.selectedCifa = state.selectedCifa
            data1.selectedStatus = state.selectedStatus
            data1.selectedYear = state.selectedYear
            data1.cifaList = data1.cifa
            data1.so = state.so

            let summary = SummaryDb(2, data1)
            console.log('SUMMARY', summary)
            return {
                ...state,
                loaded: true,
                dataLoaded: true,
                compareP2: data1.p2,
                qb: data1.qb,
                qbC_num: data1.qbC_num,
                noP2: data1.noP2,
                ignored: data1.ignored,
                latest: data1.latest,
                statusList: data1.status,
                cifaList: data1.cifa,
                summary: summary.data,
                summaryHeaders: summary.headers,
            }
        case "LOADED_SUMMARY":
            console.log('LOADED_SUMMARY', action)
            let thisData = {}
            thisData.qb = state.qb
            thisData.qbC_num = state.qbC_num
            thisData.noP2 = state.noP2
            thisData.ignored = state.ignored
            thisData.latest = state.latest
            thisData.statusList = state.statusList
            thisData.cifaList = state.cifaList
            thisData.so = state.so
            let summary1 = SummaryDb(2, thisData)
            return {
                ...state,
                summary: summary1.data,
                summaryHeaders: summary1.headers,

            }

        case "UPDATE_SUMMARY":
            console.log('UPDATE_SUMMARY', action)
            let objs = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                objs[item] = action.data[item];
            });
            if(action.selectedSummary === 'Original') {
                if (action.reset) {
                    console.log('RESET_SUMMARY', action)
                    objs.summary = []
                    objs.poArrayChanged = true
                    objs.dataLoaded = false
                } else {
                    objs.summary = Summary(2, state, action.data)
                }
            }else{

            }

            return objs;
        case 'yoy':
            console.log('yoy', action)
            return {
                ...state,
                yoy: YOY(action.payload.data),
                loaded: true,
                dataLoaded: true,
            }
        case "CHART":
            let data2 = action.payload.data
            //let update2 = DbUpdate(data2.t_invoice, data2.t_po)
            data2.selectedCifa = state.selectedCifa
            data2.selectedStatus = state.selectedStatus
            data2.selectedYear = state.selectedYear
            data2.selectedChart = state.selectedChart
            data2.selectedRegion = state.selectedRegion
            data2.t_po = state.t_po
            data2.t_region = state.t_region
            let arrC = []
            data2.cifa.forEach(c => {
                if (c !== 'All' && c !== 'PAID') {
                    arrC.push(c)
                }
            })
            data2.cifaArray = arrC
            let chart1 = Chart(data2)
            return {
                ...state,
                loaded: true,
                dataLoaded: true,
                compareP2: data2.p2,
                qb: data2.qb,
                qbC_num: data2.qbC_num,
                noP2: data2.noP2,
                ignored: data2.ignored,
                latest: data2.latest,
                statusList: data2.status,
                cifaList: data2.cifa,
                cifaArray: data2.cifaArray,
                chart: chart1
            }
        case "LOADED_CHART":
            console.log('LOADED_CHART', action)
            let thisData1 = {}
            thisData1.qb = state.qb
            thisData1.p2 = state.compareP2
            thisData1.qbC_num = state.qbC_num
            thisData1.noP2 = state.noP2
            thisData1.ignored = state.ignored
            thisData1.latest = state.latest
            thisData1.statusList = state.statusList
            thisData1.cifaList = state.cifaList
            thisData1.so = state.so
            thisData1.t_po = state.t_po
            thisData1.t_region = state.t_region
            thisData1.selectedChart = state.selectedChart
            thisData1.selectedRegion = state.selectedRegion
            thisData1.selectedCifa = state.selectedCifa
            thisData1.selectedStatus = state.selectedStatus
            thisData1.selectedYear = state.selectedYear
            thisData1.cifaArray = state.cifaArray
            let chart2 = Chart(thisData1)
            return {
                ...state,
                chart: chart2
            }
        case "UPDATE_CHART":
            console.log('UPDATE_CHART', action)
            let objh = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                objh[item] = action.data[item];
            });

            if (action.reset) {
                console.log('RESET_CHART', action)
                objh.chart = null
                objh.poArrayChanged = true
                objh.dataLoaded = false
            } else {
                objh.chart = Chart(state, action.data)
            }
            return objh;

        case "cifaChecked":
            let objC = {
                ...state
            }
            let arr = state.cifaArray
            if (action.payload.checked) {
                arr.push(action.payload.value)
            } else {
                arr = arr.filter(item => item !== action.payload.value)
            }
            objC.cifaArray = arr
            objC.p2 = state.compareP2
            objC.chart = Chart(objC)

            return objC;

        case "saveFavorite":
            console.log('saveFavorite', action.payload.data.t_favorite.find(obj => obj.c_name === action.params.body.data.c_name).c_name)
            let fav1 = ['None']
            action.payload.data.t_favorite.forEach(function (r) {
                if (!fav1.find(s => s == r.c_name)) {
                    fav1.push(r.c_name);
                }
            })
            return {
                ...state,
                favoriteList: fav1,
                t_favorite: action.payload.data.t_favorite,
                selectedFavorite: null
            }
        case "updateFavorite":
            console.log('updateFavorite', action)
            let fav2 = ['None']
            action.payload.data.t_favorite.forEach(function (r) {
                if (!fav2.find(s => s == r.c_name)) {
                    fav2.push(r.c_name);
                }
            })
            return {
                ...state,
                favoriteList: fav2,
                t_favorite: action.payload.data.t_favorite,
                selectedFavorite: action.payload.data.t_favorite.find(obj => obj.c_name === action.params.body.data.c_name).c_name
            }
        case "deleteFavorite":
            console.log('deleteFavorite', action)
            let fav3 = ['None']
            action.payload.data.t_favorite.forEach(function (r) {
                if (!fav3.find(s => s == r.c_name)) {
                    fav3.push(r.c_name);
                }
            })
            return {
                ...state,
                favoriteList: fav3,
                t_favorite: action.payload.data.t_favorite,
                selectedFavorite: null,
                poArray: []
            }

        case "CANCEL":
            return {
                ...state,
                cancel: true,
                picField: null
            }
        case "EDIT":
            return {
                ...state,
                edit: action.data,
                historyLength: addHistory(state)
            }
        case "CREATE_ROW":
            console.log('CREATE_ROW', action)
            let cObj = {
                ...state,
            }
            cObj[action.payload.data.table].unshift(action.payload.data[action.payload.data.table])
            return cObj

        case "DELETE_ROW":
            console.log('DELETE_ROW', action)
            let dObj = {
                ...state,
            }
            dObj[action.table] = state[action.table].filter(function (obj) { return obj.recid !== action.recid });
            return dObj
        case "SORT":
            let obj1 = {
                ...state
            }
            //  toggle sort direction
            if (typeof sortManager[action.data.id] === 'undefined') {
                sortManager[action.data.id] = true;
            }
            else {
                sortManager[action.data.id] = !sortManager[action.data.id];
            }
            if (sortManager[action.data.id]) {
                obj1[action.data.source].sort((a, b) => (a[action.data.column] > b[action.data.column]) ? 1 : -1);
            } else {
                obj1[action.data.source].sort((b, a) => (a[action.data.column] > b[action.data.column]) ? 1 : -1);
            }
            return obj1

        case "UPDATE_LIST":
            let initObj = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                initObj[item] = action.data[item];
            });
            return initObj;

        case "UPDATE_SELECTED":
            console.log('UPDATE_SELECTED', action)
            let obj = {
                ...state,
            }
            Object.keys(action.data).forEach(function (item) {
                obj[item] = action.data[item];
            });
            return obj;
        case "UPDATE_FAVORITE":
            console.log('UPDATE_SELECTED', action)
            let obj11 = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                obj11[item] = action.data[item];
            });
            if (action.data.selectedFavorite = 'None') {
                obj11.poArray = []
            }
            return obj11;
        case "UPDATE_SO":
            console.log('UPDATE_SO', action)
            let objso = {
                ...state
            }
            Object.keys(action.data).forEach(function (item) {
                objso[item] = action.data[item];
            });
            objso.soSummary = SalesOrderSummary(state.so, state, action.data)
            return objso;

        case "SELECT_FAVORITE":
            console.log('SELECT_FAVORITE', action)
            let objf = {
                ...state

            }

        case "FILTER":
            let f = state.filter;
            f[action.data.id] = action.data
            return {
                ...state,
                filter: f
            }
        case "CURRENT_REGION":
            return {
                ...state,
                currentRegion: action.data.region
            }
        case "CLEAR_PO_ARRAY":
            console.log('CLEAR_PO_ARRAY')
            return {
                ...state,
                poArray: [],
                favoriteList: ['None'],
                selectedFavorite: null,
                compareLoaded: false,
                summaryLoaded: false,
            }
        case "ADD_PO_ARRAY":
            return {
                ...state,
                poArray: action.data,
                compareLoaded: false,
                summaryLoaded: false,
            }
        default:
            return state;
    }
}
