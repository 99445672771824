import ResolveRegion from "./ResolveRegion";
import moment from "moment";

export default function Chart(state, selected) {
        console.log('Chart',state)
    if(selected){
        Object.keys(selected).forEach(function (item) {
            state[item] = selected[item];
        });
    }
    if(state.selectedChart === 'Cluster Stacked'){
        let p2 = state.p2;
        let qb = state.qb;
        let res = [];
        let height = 600;
        let poArray = [];
        qb.forEach(function (q) {
            if (state.selectedYear === 'All' || q.c_date.includes(state.selectedYear)) {
                if(!poArray.includes(q.c_po)){
                    poArray.push(q.c_po)
                }
                let po = state.latest.find(s => s.c_base_inv_num == q.c_base_inv_num);
                if (po) {
                    if(po.c_CIFA_status === 'PAID'){
                        q.paid = po.c_invoice_amount;
                    }else{
                        q.owed = po.c_invoice_amount;
                    }
                    q.cifa = po.c_CIFA_status;
                }else{
                    q.owed = q.c_invoice_amount;
                }
                res.push(q)
                // q.po = false
                // p2.forEach(function (p) {
                //
                //     if (p.c_invoice_num === q.c_num && !q.po && p.c_CIFA_status === 'PAID') {
                //         q.po = true;
                //         q.paid = p.c_invoice_amount;
                //     }else{
                //         q.cifa = p.c_CIFA_status;
                //     }
                // })
                // res.push(q)
            }
        })
        let interval = 'year';
        let format = 'YYYY';
        if(state.selectedYear === 'All'){
            interval = 'year'
            format = 'YYYY';
        }else{
            interval = 'quarter'
            format = 'YYYY-Q';
        }
        //console.log('interval',interval)
        const groups = res.reduce((acc, obj) => {
            // create a composed key: 'year'

            let period = moment(obj.c_date).startOf(interval).format(format);
            const key = `${period}`;
            // add this key as a property to the result object
            if (!acc[key]) {
                acc[key] = [];
            }
            // push the current date that belongs to the year calculated before
            acc[key].push(obj);
            return acc;
        }, {});
        console.log('groups',groups)

        let cats = [];
        let series = [];
        if(state.selectedRegion === 'All'){
            state.t_region.forEach(function (item) {
                cats.push(item.c_name)
            })
        }else{
            cats = poArray
            cats = cats.sort((b, a) => a - b);
            if(state.selectedYear === 'All'){
                if(cats.length > 4){
                    height = cats.length * 90;
                }
            }else{
                if(cats.length > 4){
                    height = cats.length * 110;
                }

            }
        }

        Object.keys(groups).sort((a, b) => b - a).forEach(function (item) {
            //console.log('item',item)

            let paidArr = [];
            let paidAmountArr = [];
            let unpaidArr = [];
            let unpaidAmountArr = [];
            cats.forEach(function (cat) {
                paidArr.push(0)
                paidAmountArr.push(0)
                unpaidArr.push(0)
                unpaidAmountArr.push(0)
            })
            groups[item].forEach(function (i) {
                //console.log(i)
                let index
                if(state.selectedRegion === 'All'){
                    index = cats.indexOf(state.t_region.find(r => r.recid === i.c_region_fk).c_name)
                }else{
                    index = cats.indexOf(i.c_po)
                }

                if (i.paid) {
                    paidArr[index]++
                    paidAmountArr[index] += i.paid
                }else{
                    if(state.cifaArray.includes(i.cifa)){
                        unpaidArr[index]++
                        unpaidAmountArr[index] += i.owed
                    }
                }
            })

            series.push({
                name: item+' Paid',
                group: item,
                data:paidArr,
                amount:paidAmountArr
            })
            series.push({
                name: item+' Owed',
                group: item,
                data:unpaidArr,
                amount:unpaidAmountArr
            })

        });
        console.log('series', series)
        let data = state = {
            series: series,
            options: {
                chart: {
                    type: 'bar',
                    height: height,
                    stacked: true,
                    animations: {
                        enabled: false
                    }
                },

                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                dataLabels: {
                    formatter: (val, opt) => {
                        const yValue = opt.w.config.series[opt.seriesIndex].amount[opt.dataPointIndex];
                        return `${val} Inv. = $${yValue.toFixed(2)}`;
                    },
                    style: {
                        colors: ['#000']
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true
                    }
                },
                xaxis: {
                    categories: cats,
                    labels: {
                        formatter: (val) => {
                            return val + " Invoices"
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val, opt) => {
                            const yValue = opt.w.config.series[opt.seriesIndex].amount[opt.dataPointIndex];
                            return `${val} Invoices = $${yValue.toFixed(2)}`;
                        },
                    }
                },
                fill: {
                    opacity: 1,
                },
                colors: ['#026dbe', '#02a16b',
                    '#58a2da', '#61c9a6',
                    '#84b7e0', '#88d0b7',
                    '#b2e3fc', '#bdf8e9',],
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                }
            },


        };
        return data;

    }

    //console.log('chart',state.selectedChart)
    if(state.selectedChart === 'Paid VS Owed'){
        let p2 = state.p2;
        let qb = state.qb;
        let res = []
        qb.forEach(function (q) {
            if (state.selectedYear === 'All' || q.c_date.includes(state.selectedYear)) {
                q.po = false
                p2.forEach(function (p) {

                        if (p.c_invoice_num === q.c_num && !q.po && p.c_CIFA_status === 'PAID') {
                            q.po = true;
                            q.paid = p.c_invoice_amount;
                        }
                })
                res.push(q)
            }
        })

        const groups = res.reduce((acc, obj) => {
            // create a composed key: 'year-week'

            let week = moment(obj.c_date).startOf('week').format("MM / DD / YYYY");
            //${moment(obj.c_date).year()}-
            const yearWeek = `${week}`;
            // add this key as a property to the result object
            if (!acc[yearWeek]) {
                acc[yearWeek] = [];
            }
            // push the current date that belongs to the year-week calculated befor
            acc[yearWeek].push(obj);
            return acc;
        }, {});

        let cats = [];
        let paidArray = [];
        let billedArray = [];

        Object.keys(groups).forEach(function (item) {
            //console.log(groups[item].length)
            let paid = 0;
            let billed = 0;
            groups[item].forEach(function (i) {
                if (i.paid) {
                    paid += i.paid
                }
                billed += i.c_amount;
            })
            cats.push(item)
            paidArray.push(paid.toFixed(2))
            billedArray.push((billed - paid).toFixed(2))
        });

        const data = {
            series: [{
                name: 'Paid',
                data: paidArray
            }, {
                name: 'Owed',
                data: billedArray
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: paidArray.length * 30 + 100,
                    stacked: true,
                    animations: {
                        enabled: false
                    }
                },

                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: 'Paid VS Owed'
                },
                xaxis: {
                    categories: cats,
                    labels: {
                        formatter: function (val) {
                            return val.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: undefined
                    },
                },
                dataLabels: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                        colors: ['#000']
                    },
                    formatter: function (val, opts) {
                        return val.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            },
        }
        return (data)
    }

    if(state.selectedChart === 'Amount VS Reserve'){
        let p2 = state.p2;
        let qb = state.qb;
        let pos = []
        p2.forEach(function (p) {

                if ((state.selectedCifa === 'All' || p.c_CIFA_status === state.selectedCifa) && (state.selectedStatus === 'All' || p.c_status === state.selectedStatus) && (state.selectedYear === 'All' || p.c_date.includes(state.selectedYear))) {
                    p.hasQB = 'false';
                    p.qb = []
                    qb.forEach(function (q) {
                            let hasQB = false;
                            if (q.c_num == p.c_invoice_num) {
                                p.qb.push(q)
                                hasQB = true
                            }
                            if (hasQB) {
                                q.hasP2 = 'true'
                                p.hasQB = 'true';
                            }

                    })
                    let found = pos.find(s => s.c_num == p.c_num);
                    if (found) {
                        let Ifound = found.invoices.find(s => s.c_invoice_num == p.c_invoice_num);
                        if (Ifound) {
                            Ifound.c_attempts++
                        } else {
                            p.c_attempts = 1;
                            found.invoices.push(p)
                        }
                    } else {
                        let obj = {
                            c_num: p.c_num,
                            c_jb: p.c_jb,
                            c_date: p.c_date,
                            c_status: p.c_status,
                            c_reserve: p.c_reserve,
                            hasQB: p.hasQB,
                            c_attempts: 1,
                            invoices: []
                        }
                        p.c_attempts = 1;
                        obj.invoices = [p]
                        pos.push(obj)
                    }
                }

        })
        pos.sort((a, b) => (a.c_date > b.c_date) ? -1 : 1);
        let noP2Invoices = qb.filter(q => !q.hasP2);
        let invPo = [];
        let grandTotal = 0

        let cats = [];
        let reserveArray = [];
        let amountArray = [];

        pos.forEach(function (pur) {
            let total = 0
            let totalReserve = 0;
            cats.push(pur.c_num);
            //console.log('pur.c_reserve',pur)

            invPo.push({ type: 'P.O.', c_num: pur.c_num })
            pur.invoices.forEach(function (inv) {
                total += inv.c_invoice_amount
                totalReserve += inv.c_reserve;
                inv.type = 'Invoice'
                invPo.push(inv);
            })

            amountArray.push(total);
            reserveArray.push(totalReserve);
            grandTotal += total
            invPo.push({ type: 'Total', c_invoice_amount: total })
            invPo.push({})
        })
        invPo.push({ type: 'Grand Total', c_invoice_amount: grandTotal })

        const data = {
            series: [{
                name: 'Amount',
                data: amountArray
            }, {
                name: 'Reserve',
                data: reserveArray
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: reserveArray.length * 60 + 100,
                    animations: {
                        enabled: false
                    }
                },

                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                colors: ['#000']
                            },
                            formatter: function (val, opts) {
                                return val.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                })
                            }
                        },
                        tooltip: {
                            y: {
                                formatter: function (val) {
                                    return val.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    })
                                }
                            }
                        },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                    title: {
                        text: 'PO Amount VS Reserve'
                    },
                xaxis: {
                    categories: cats,
                    labels: {
                        formatter: function (val) {
                            return val.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            })
                        }
                    }
                },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    },
            },
            // series: [{
            //     name: 'Amount',
            //     data: amountArray
            // }, {
            //     name: 'Reserve',
            //     data: reserveArray
            // }],
            // options: {
            //     chart: {
            //         type: 'bar',
            //         height: 350,
            //         stacked: true,
            //     },
            //     plotOptions: {
            //         bar: {
            //             horizontal: true,
            //         },
            //     },
            //     stroke: {
            //         width: 1,
            //         colors: ['#fff']
            //     },
            //     title: {
            //         text: 'Amount VS Reserve'
            //     },
            //     xaxis: {
            //         categories: cats,
            //         labels: {
            //             formatter: function (val) {
            //                 return val.toLocaleString('en-US', {
            //                     style: 'currency',
            //                     currency: 'USD',
            //                 })
            //             }
            //         }
            //     },
            //     yaxis: {
            //         title: {
            //             text: undefined
            //         },
            //     },
            //     dataLabels: {
            //         enabled: true,
            //         offsetX: 0,
            //         style: {
            //             colors: ['#000']
            //         },
            //         formatter: function (val, opts) {
            //             return val.toLocaleString('en-US', {
            //                 style: 'currency',
            //                 currency: 'USD',
            //             })
            //         }
            //     },
            //     tooltip: {
            //         y: {
            //             formatter: function (val) {
            //                 return val.toLocaleString('en-US', {
            //                     style: 'currency',
            //                     currency: 'USD',
            //                 })
            //             }
            //         }
            //     },
            //     fill: {
            //         opacity: 1
            //     },
            //     legend: {
            //         position: 'top',
            //         horizontalAlign: 'left',
            //         offsetX: 40
            //     }
            // },
        }
        return (data)

    }

}
