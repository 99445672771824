import ResolveRegion from "./ResolveRegion";

export default function SalesOrderSummary(so, state, selected) {
    if(selected){
        Object.keys(selected).forEach(function (item) {
            state[item] = selected[item];
        });
    }
    let regionFK = ResolveRegion('number',state);
    console.log('regionFK',regionFK)
    let pos = []
    so.forEach(function (s) {
        if(s.c_region_fk == regionFK) {
            if ((state.selectedPo === 'All' || s.c_num === state.selectedPo) && (state.selectedYear === 'All' || s.c_date.includes(state.selectedYear))) {
                let found = pos.find(p => p.c_po == s.c_po);
                if (found) {
                    found.invoices.push(s)
                } else {
                    let obj = {
                        c_num: s.c_num,
                        c_date: s.c_date,
                        c_amount: s.c_amount,
                        c_memo: s.c_memo,
                        c_po: s.c_po,
                        c_region_fk: s.c_region_fk,
                        invoices: []
                    }
                    obj.invoices = [s]
                    pos.push(obj)
                }
            }
        }
    })

    pos.sort((a, b) => (a.c_date > b.c_date) ? -1 : 1);
    let invPo = [];
    let grandTotal = 0;
    pos.forEach(function (pur) {
        let total = 0;

        invPo.push({ type: 'P.O.', c_po: pur.c_po})
        pur.invoices.forEach(function (inv) {
            total += inv.c_amount;
            inv.type = 'Sales Order'
            invPo.push(inv);
        })
        grandTotal += total;
        invPo.push({ type: 'Total', c_amount: total})
        invPo.push({})
    })
    invPo.push({ type: 'Grand Total', c_amount: grandTotal})

    console.log('so sum',invPo)
    return (invPo)

}
