import moment from 'moment';
export default function Compare(state, selected) {
    console.log('Compare', state)
    if(selected){
        Object.keys(selected).forEach(function (item) {
            state[item] = selected[item];
        });
    }
    let p2 = [...state.latest, ...state.noP2, ...state.ignored];
    let pos = []

    p2.forEach(function (p) {
        let found = pos.find(s => s.c_num == p.c_num);  //Is PO part of pos yet?
        if (found) {    //PO found
            //Is invoice part or PO invoices yet?
            if (!found.invoices.find(i => i.c_invoice_num === p.c_invoice_num)) {
                if(!p.noP2) {
                    p.c_attempts = state.p2.filter(t => t.c_base_inv_num === p.c_base_inv_num).length;
                }
                if(p.c_date){
                    p.c_date = moment.utc(p.c_date).format('YYYY-MM-DD');
                }
                if(p.c_invoice_scheduled_to_pay){
                    p.c_invoice_scheduled_to_pay = moment.utc(p.c_invoice_scheduled_to_pay).format('YYYY-MM-DD');
                }
                found.invoices.push(p)
            }
        } else {    //PO not found
            let obj = {
                c_num: p.c_num,
                c_jb: p.c_jb,
                c_date: p.c_date,
                c_status: p.c_status,
                hasQB: p.hasQB,
                invoices: []
            }
            if(!p.noP2){
                p.c_attempts = 1;
            }
            obj.invoices = [p]
            pos.push(obj)
        }
    })
    // which qb invoices are NOT in P2
    pos.sort((a, b) => (a.c_date > b.c_date) ? -1 : 1);
    let invPo = [];
    let grandTotal = 0;
    let grandReserve = 0;
    let grandAllocation = 0

    pos.forEach(function (pur) {
        let voids = [];
        let ignoreds = [];
        let excludes = [];

        pur.invoices.sort(fieldSorter(['c_status', 'c_CIFA_status']));
        let allocation
        let foundPo = state.p2.find(s => s.c_num == pur.c_num);
        if (foundPo) {
            allocation = foundPo.c_allocation
        }
        let total = 0;
        let totalReserve = 0;
        let currentStatus = ''
        let sum = 0;
        let finalInv;
        invPo.push({type: 'P.O.', c_num: pur.c_num, c_allocation: allocation})
        pur.invoices.forEach(function (inv) {

            if(currentStatus ===''){
                currentStatus = inv.c_CIFA_status;
            }

            if ((state.selectedCifa === 'All' || inv.c_CIFA_status === state.selectedCifa) && (state.selectedStatus === 'All' || inv.c_status === state.selectedStatus) && (state.selectedYear === 'All' || (inv.c_date && inv.c_date.includes(state.selectedYear)))) {
                if(inv.c_exclude == 1) {
                    inv.type = 'Excluded'
                    if (inv.noP2) {
                        inv.type = 'NoP2 Ex'
                    }
                    excludes.push(inv);
                }else {
                    if (inv.noP2) {
                        if (inv.void) {
                            inv.type = 'Void No P2'
                            voids.push(inv);
                        } else {
                            inv.type = 'No P2'
                        }
                    } else if (inv.mismatch) {
                        inv.type = 'Mismatch'
                    } else if (inv.c_status === 'Ignored') {
                        inv.type = 'Ignored'
                        ignoreds.push(inv);
                    } else{
                        inv.type = 'Invoice'
                    }
                }

                if (inv.type == 'Invoice' || inv.type == 'Mismatch' || inv.type == 'No P2') {

                    total += inv.c_invoice_amount || 0;
                    totalReserve += inv.c_reserve || 0;
                    finalInv = inv
                    if(inv.c_CIFA_status === currentStatus){
                        sum += inv.c_invoice_amount;
                    }else{
                        invPo.push({type: 'Sub Total', c_num: inv.c_num, c_invoice_amount: sum});
                        sum = inv.c_invoice_amount;
                        currentStatus = inv.c_CIFA_status;
                    }
                    invPo.push(inv); //********* Added
                }
            }
        })
        grandTotal += total;
        grandReserve += totalReserve;
        grandAllocation += allocation
        if(total !== sum) {
            invPo.push({type: 'Sub Total', c_num: finalInv.c_num, c_invoice_amount: sum});
        }
        invPo.push({
            type: 'Total',
            c_num: pur.c_num,
            c_invoice_amount: total,
            c_reserve: totalReserve,
            c_allocation: allocation
        })
        voids.forEach(function (v) {
            let t = v
            t.type = 'Void'
            invPo.push(v)
        })
        ignoreds.forEach(function (v) {
            invPo.push(v)
        })
        excludes.forEach(function (v) {
            invPo.push(v)
        })
        invPo.push({})
    })
    invPo.push({ type: 'Grand Total', c_invoice_amount: grandTotal , c_reserve: grandReserve, c_allocation:grandAllocation})

    return ({comp:invPo,report:pos})
}

function fieldSorter(fields) {
    var maps = [];
    fields.map(function (o) {
        var dir = +1;
        if (o[0] === '-') {
            dir = -1;
            o=o.substring(1);
        }
        maps.push({fn:o, dir:dir});
    });
    return function (a, b) {
        var ret = 0;
        maps.some(function (o) {
            if (a[o.fn] > b[o.fn]) ret = o.dir;
            else if (a[o.fn] < b[o.fn]) ret = -o.dir;
            else ret = 0;
            return ret !== 0;
        });
        return ret;
    };
}
